import React from 'react';
import PropTypes from 'prop-types';
import {graphql, Link, useStaticQuery} from 'gatsby';

import Layout from '../../../../components/Layout';
import SEO from '../../../../components/seo';
import Content from '../../../../components/Content';
import Block from '../../../../components/Block';
import Hero from '../../../../components/Hero';
import Anchors from '../../../../components/Anchors';
import Subnavigation from '../../../../components/Subnavigation';
import PersonBadge from '../../../../components/PersonBadge';
import ContentImage from '../../../../components/ContentImage';
import {
	HighlightedParagraph,
	HighlightedTitle,
} from '../../../../components/highlightedText';
import Breadcrumb from '../../../../components/Breadcrumb';
import {lang} from '../../../../helpers/lang';
import {getSectorSubpageLinkByKeys} from '../../../../selectors/data';
import {Icon} from '@gisatcz/ptr-atoms';

const UtilityAndInfrastructure = ({pageContext}) => {
	const data = useStaticQuery(graphql`
		{
			background: file(relativePath: {eq: "pages/work/sectors/bridge.jpg"}) {
				childImageSharp {
					gatsbyImageData(layout: FULL_WIDTH)
				}
			}
		}
	`);

	return (
		<Layout theme={pageContext.sector.theme}>
			<SEO title={pageContext.sector.title} lang={lang} />
			<Hero
				backgroundImageFluid={data.background.childImageSharp.gatsbyImageData}
			>
				<Breadcrumb crumbs={pageContext?.crumbs} />
				<HighlightedTitle>Infrastructure and utilities</HighlightedTitle>
				<HighlightedParagraph>
					Earth observation solutions allow early identification of risks and
					support planning, construction and maintenance of utility and
					infrastructure assets.
				</HighlightedParagraph>
			</Hero>
			<Block light>
				<Content>
					<Subnavigation>
						<Anchors>
							<a href={'#roads-and-railways'}>Roads and railways</a>
							<a href={'#tunnels-and-undermining'}>Tunnels and undermining</a>
							<a href={'#bridges'}>Bridges</a>
							<a href={'#revitalisations-and-recultivations'}>
								Revitalisations and recultivations
							</a>
							<a href={'#dams-and-tailing-dams'}>Dams and tailing dams</a>
							<a href={'#corner-reflectors'}>Artificial corner reflectors</a>
							<a href={'#change-detection'}>Assets change detection </a>
						</Anchors>
						<PersonBadge
							note={pageContext.sector.contactNote}
							email={pageContext.sector.contactPerson}
						/>
					</Subnavigation>
				</Content>
			</Block>
			<Block light>
				<Content>
					<p>
						Engineers and planners are facing various challenges during the
						asset's life cycles:
						<ul>
							<li>site selection and construction planning</li>
							<li>construction monitoring</li>
							<li>infrastructure operational monitoring</li>
						</ul>
						A critical task at any stage of the cycle is early identification of
						risks, essential for response targeting and reduction of adverse
						impacts, mitigation and maintenance costs.
					</p>
					<p>
						To evaluate structural stability and surface deformations we offer
						scalable and customizable solutions based on a suite of
						multi-temporal satellite interferometry (MT InSAR) techniques. The
						solution is applicable both to high-resolution (Sentinel-1) and
						very-high resolution (commercial) satellite data depending on detail
						needed by customers. It allows us to monitor assets of linear, area
						or spot-like nature. Deformations detected for objects indicate
						problems of structural nature or subsoil settlement. Ground motions
						detected in near or wider surroundings are mostly related to threats
						induced by ground subsidence, slow slope deformations or
						undermining.
					</p>
				</Content>
			</Block>
			<Block light>
				<Content>
					<h2 id={'roads-and-railways'}>Roads and railways</h2>
					<p>
						MT InSAR technique helps to prevent damages or potential collapse of
						embankments as markers derived from measurement time series can
						indicate forthcoming failure. Detected deformation patterns can be
						correlated with measurements from traditional monitoring systems
						(geodetic and GNSS, water pressure gauges, inclinometers,
						temperature and precipitation measurements) and existing survey
						networks. Monitoring from multiple directions helps determine real
						vectors of displacements. We have developed custom single- and
						double-flip design of artificial corner reflectors accustomed to
						mounting directly to infrastructure objects or nearby.
					</p>
					{/*<ContentImage*/}
					{/*	fluid={null}*/}
					{/*	legend={`Obr: Ukazka D8 a SZDC Dalovice vcetne time series. Na D8 klastr active defo zone...`}*/}
					{/*/>*/}
				</Content>
			</Block>
			<Block light>
				<Content>
					<h2 id={'tunnels-and-undermining'}>Tunnels and undermining</h2>
					<p>
						Gisat provides detection of extent, grading and chronology of ground
						settlements during and after shallow tunnel drilling - e.g. subway,
						road underpassess and tunnels in urban areas. Sinking bowl resulting
						from drilling or ground water withdrawal impacts structural
						stability of infrastructure and buildings on surface. MT-InSAR
						allows regular evaluation of stability during ongoing construction
						phases and raise alarm amid displacement exceeding warning state.
						Comparison of deformation rate chronology supports evidence-based
						assessment of potential damage causalities and transfer of
						liability.
					</p>
				</Content>
			</Block>
			<Block light>
				<Content>
					<h2 id={'bridges'}>Bridges</h2>
					<p>
						Though challenging due to mixing up with thermal dilatation and
						other influencing factors, long time series of InSAR measurements
						allow us to detect certain deformations patterns for parts of bridge
						structures. We have developed a multicriterial methodological
						framework for assessing MT InSAR feasibility for bridge monitoring
						that facilitate finding feasible and cost effective monitoring
						solutions for specific bridge characteristics
					</p>
					{/*<ContentImage*/}
					{/*	fluid={null}*/}
					{/*	legend={`Obr: Ukazka Ostrava (prepracovat do 3D) vcetne koutovych odrazecu`}*/}
					{/*/>*/}
				</Content>
			</Block>
			<Block light>
				<Content>
					<h2 id={'revitalisations-and-recultivations'}>
						Revitalisations and recultivations
					</h2>
					<p>
						Interferometry provides insight into uneven distribution of soil
						consolidation and velocity for large dump sites. Measurements from
						currently or formerly operational satellites provide either
						up-to-date or historic dump settlement rates spanning as far back as
						to the 1990's. Results from our interferometric solution facilitate
						calibration of geotechnical ground-settlement models to provide more
						accurate projections of the consolidation process.
					</p>
				</Content>
			</Block>
			<Block light>
				<Content>
					<h2 id={'dams-and-tailing-dams'}>Dams and tailing dams</h2>
					<p>
						Satellite interferometry helps identify tailings dam’s weak points
						that are subject to higher rates of deformations where other risky
						patterns can be detected from deformation trend lines. Detected
						deformations can be temporally correlated to oscillations of liquid
						levels in the reservoir.
					</p>
				</Content>
			</Block>
			<Block light>
				<Content>
					<h2 id={'corner-reflectors'}>Artificial corner reflectors</h2>
					<p>
						Topographic and ground surface characteristics, vegetation and snow
						cover and stochastic nature of target detection (persistent or
						distributed scatterers) may hinder deformation mapping under certain
						circumstances. To overcome this caveat, a network of artificial
						corner reflectors can be installed within the area of interest. Such
						a network allows all-season monitoring and connection to existing
						survey networks and measurements.
					</p>
				</Content>
			</Block>
			<Block light>
				<Content>
					<h2 id={'change-detection'}>Assets change detection </h2>
					<p>
						Assets operators need to react to changes occuring in protective
						corridors along the linear infrastructure and utility networks.
						Illicit activities such as unpermitted built-up, earthworks or
						dumping may impose direct risks to traffic or proper operations, or
						introduce additional costs for maintenance or legal and
						administrative procedures. Besides anthropogenic factors, vegetation
						volume, height and health represents another phenomena that need to
						be tackled in scope of management plans in order to assure full
						operability of the networks.
					</p>
					<p>
						The above mentioned phenomena can be mapped from commercial very
						high resolution imagery. For large linear networks and grids,
						however, automated solutions with reduced cost for data are
						demanded. Our solution is based on detection of changes and
						indicators (markers) from long time series of high resolution data
						from Sentinel-1 (radar, all weather) and Sentinel-1 (optical)
						imagery. Results indicate potential objects of interest or
						vegetation growth hot-spots to be inspected by targetted field
						survey or UAV technology.
					</p>
				</Content>
			</Block>
		</Layout>
	);
};

UtilityAndInfrastructure.propTypes = {
	pageContext: PropTypes.object.isRequired,
};

export default UtilityAndInfrastructure;
